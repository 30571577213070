<template>
  <b-container class="bg-white" fluid>
    <b-row class="mb-4">
      <b-col>
        <b-link :to="{ name: 'AppReportingZebra' }">back to data loading</b-link>
        <b-button variant="outline-primary" class="mb-2 mx-4" @click="generateReport">download report</b-button>
        <b-button class="mb-2 mx-4" variant="outline-primary" v-b-toggle.sidebar-1>report options</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <div id="reportExport">
            <div style="font-family: Arial; font-size: 12px">

                <div v-for="control in controls" :key="control.Key">
                  <div>
                    <table style="font-family: Arial; font-size: 12px; background-color: #00338D; color: white;" width=100%>
                       <tr>
                         <td style="background-color: #00338D; color: white; font-size: 14px; font-weight: 700; padding: 10px 5px 10px 5px;">
                          <span v-if="options.key.value">{{control['Key']}} | </span>
                          {{control['Control Instance ID']}} {{control['Summary']}}
                          </td>
                        </tr>
                    </table>
                    <br />
                    <div><strong style="color: #00348d;">KPMG view on control expectations</strong><br /><markdown :content="control['Objective']"></markdown></div>
                    <div><strong style="color: #00348d;">Control description</strong><markdown :content="control['Control Description']"></markdown></div>
                    <div><strong style="color: #00348d;">Control ownership: </strong> {{control['Function']}}</div>
                    <div><strong style="color: #00348d;">Frequency:</strong> {{control['Frequency1']}}<span v-if="control['Frequency2']">, {{ control['Frequency2'] }}</span><span v-if="control['Frequency3']">, {{ control['Frequency3'] }}</span><span v-if="control['Frequency4']">, {{ control['Frequency4'] }}</span></div>
                    <div><strong style="color: #00348d;">Business area: </strong> {{control['Business Area']}}</div>
                    <div><strong style="color: #00348d;">Relevant ORIs:</strong> {{control['Component1']}}<span v-if="control['Component2']">, {{ control['Component2'] }}</span><span v-if="control['Component3']">, {{ control['Component3'] }}</span><span v-if="control['Component4']">, {{ control['Component4'] }}</span><span v-if="control['Component5']">, {{ control['Component5'] }}</span><span v-if="control['Component6']">, {{ control['Component6'] }}</span><span v-if="control['Component7']">, {{ control['Component7'] }}</span></div>
                    <br />
                    <div><strong style="color: #00348d;">Design effectiveness assessment:</strong> {{control['TOD Validation']}}</div>
                    <div><strong style="color: #00348d;">Operating effectiveness assessment:</strong> {{control['Rating']}}</div>
                    <div><strong style="color: #00348d;">Overall control assessment: <span :style="`font-size: 1.2em; color: ${control.color}`">{{control['RAG Status'].toUpperCase()}}</span></strong></div>
                    <br  v-if="control.findings.length > 0" />
                    <div v-if="control.findings.length > 0">
                      <strong style="color: #00348d;">Existing issues noted by the Bank</strong>
                        <div v-for="issue in control.findings" :key="'management-finding' + control.key + issue['Finding ID']">
                          <div>
                            {{ issue.Finding }}<br />
                          </div>
                        </div>
                    </div>
                    <br />
                    <div>
                      <strong style="color: #00348d;">Design assessment</strong>
                      <p v-if="control.tod.length === 0">No findings noted</p>
                      <div v-if="control.tod.length > 0">
                        <div v-for="issue in control.tod" :key="issue.Key">
                          <div>
                            <br /><strong><span v-if="options.key.value">{{issue['Key']}} | </span>{{issue['Summary']}}</strong>
                            <markdown :content="issue['Description']"></markdown>
                            <div v-if="issue['Rating']">
                              <strong>KPMG rating:</strong> {{issue['Rating']}} <span v-if="issue['Rationale']"> - {{ issue['Rationale'] }}</span>
                              <br />
                              <br />
                            </div>
                            <markdown :content="'**Recommendation:** ' + (issue['Action Summary'] + ' ')"></markdown>
                            <markdown :content="'**Management response:** ' + (issue['Final Decision'] + ' ').replace('Management response: ', '')"></markdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                      <strong style="color: #00348d;">Operating effectiveness assessment</strong>
                      <p v-if="control.toe.length === 0">No findings noted</p>
                      <div v-if="control.toe.length > 0">
                        <div v-for="issue in control.toe" :key="issue.Key">
                          <div>
                            <br /><strong>{{issue['Summary']}}</strong>
                            <markdown :content="issue['Description']"></markdown>
                            <div v-if="issue['Final Decision']">
                              <markdown :content="'**Management response:** ' + (issue['Final Decision'] + ' ').replace('Management response: ', '')"></markdown>
                            </div>
                          </div>
                        </div>
                      </div>

                    <br /><br />
                  </div>
                </div>
              <h1 style="color: #00338D;">Appendix - Findings noted</h1>
              <h2 style="color: #00338D; margin-top: 50px;">Controls findings</h2>
              <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                   <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Management response</td>
                </tr>
                <tr v-for="finding in findingsControls" :key="finding.key">
                   <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Control Instance ID']}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">
                    <strong><span v-if="options.key.value">{{finding['Key']}} | </span>{{finding['Summary']}}</strong>
                    <div><markdown :content="finding['Description']"></markdown></div>
                    <div v-if="finding['Rating']">
                      <strong>KPMG rating:</strong> {{finding['Rating']}} <span v-if="finding['Rationale']"> - {{ finding['Rationale'] }}</span>
                    </div>
                    <div v-if="finding['Action Summary']"><markdown :content="'**Recommendation:** ' + (finding['Action Summary'] + ' ')"></markdown></div>
                  </td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><markdown :content="(finding['Final Decision'] + ' ').replace('Management response: ', '')"></markdown></td>
                </tr>
              </table>
            </div>
          </div>
        </b-container>
      </b-col>
    </b-row>

    <b-sidebar id="sidebar-1" title="Report settings" shadow>
      <div class="px-3 py-1" v-for="(row, index) in options" :key="'options-' + index">
        <b-form-checkbox v-model="row.value" :value="true" :unchecked-value="false">{{row.text}}</b-form-checkbox>
      </div>
    </b-sidebar>

  </b-container>
</template>

<script>
import _ from 'lodash'
import Markdown from '@/components/Markdown.vue'
import config from './config.js'

export default {
  name: "ReportingAppMainZebra",
  components: {
    Markdown
  },
  data () {
    return {
      controls: [],
      findings: [],
      findingsControls: [],
      findingsEdited: [],
      findingsNew: [],
      findingsRetired: [],
      findingsWithdrawn: [],
      options: {
        key: { value: true, text: 'key' }
      }
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'Zebra Reporting app main', action: 'open Zebra Reporting app main page' })
    const recordsRaw = {}
    config.files.forEach(x => {
      recordsRaw[x.id] = JSON.parse(localStorage.getItem('frmcloudReportingAppZebra' + x.id))
    })
    const data = recordsRaw.main
    console.log(data)
    _.remove(data, x => {
      return x['Summary'].toUpperCase().match('NO DESIGN FINDING NOTED') || x['Summary'].toUpperCase().match('NO FINDINGS NOTED')
    })

    // create findings table
    let findings = _.filter(data, x => {
      return x['Finding Type'] === 'Design assessment (control)'
    })
    findings = _.sortBy(findings, ['Control Instance ID', 'Finding Type', 'Summary'])
    this.findingsControls = findings
    let findingsOe = _.filter(data, x => {
      return x['Finding Type'] === 'Operating effectiveness assessment (control)'
    })
    findingsOe = _.sortBy(findingsOe, ['Control Instance ID', 'Finding Type', 'Summary'])
    this.findingsControls = findings.concat(findingsOe)
    this.findingsWithdrawn = _.remove(this.findingsControls, finding => {
      return finding['Validation'] === 'Withdrawn'
    })

    const controls = []
    _.each(data, x => {
      if (x['Issue Type'] === 'Control') {
        x.tod = []
        x.toe = []
        x.color = 'black'
        if (x['RAG Status'] === 'Green') {
          x.color = 'green'
        }
        if (x['RAG Status'] === 'Amber') {
          x.color = 'orange'
        }
        if (x['RAG Status'] === 'Red') {
          x.color = 'red'
        }
        controls.push(x)
      }
    })
    _.each(controls, control => {
      const tod = _.filter(data, x => {
        return x['Finding Type'] === 'Design assessment (control)' && x['Control Instance ID'] === control['Control Instance ID'] && x['Validation'] !== 'Withdrawn'
      })
      control.tod = tod
      const toe = _.filter(data, x => {
        return x['Finding Type'] === 'Operating effectiveness assessment (control)' && x['Control Instance ID'] === control['Control Instance ID'] && x['Validation'] !== 'Withdrawn'
      })
      control.toe = toe
      const findings = _.filter(this.findings, x => {
        return x.Control === control['Control Instance ID']
      })
      control.findings = findings
    })
    this.controls = controls
  },
  methods: {
    generateReport: function () {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1
      const day = String(currentDate.getDate()).padStart(2, '0')
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const formattedDateTime = `${year}-${month}-${day}-${hours}${minutes}`
      const start = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Report</title></head><body>"
      const end = "</body></html>"
      const html = start + document.getElementById('reportExport').innerHTML + end
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = `report-zebra-${formattedDateTime}.doc`
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  }
}
</script>

<style>
</style>
